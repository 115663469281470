// custom CSS styles
import "./src/css/style.scss";
import { gsap } from "gsap";

export const onClientEntry = () => {
    document.fonts.ready.then(function () {
        const header = document.querySelector('header');
        document.body.classList.add("loaded");

        // Initial header animation when fonts are ready
        gsap.to(header, {
            autoAlpha: 1,
            top: 0,
            ease: "power2",
            duration: 0.4,
            onComplete: () => {
                document.body.classList.add("navbar-loaded");
            }
        });
    });
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    // Ensure animations trigger when navigating between pages
    window.requestAnimationFrame(() => {
        // Check if navigating to the homepage
        if (location.pathname === "/") {
            const header = document.querySelector("header");
            if (header) {
                gsap.fromTo(header, { autoAlpha: 0, top: -20 }, {
                    autoAlpha: 1,
                    top: 0,
                    ease: "power2",
                    duration: 0.4
                });
            }

            // Trigger homepage content transition if available
            const content = document.querySelector(".homepage-content");
            if (content) {
                gsap.fromTo(
                    content,
                    {
                        autoAlpha: 0,
                        y: 20,
                    },
                    {
                        autoAlpha: 1,
                        y: 0,
                        ease: "power2",
                        duration: 0.5,
                    }
                );
            }

            document.body.classList.add("homepage-transition");
        } else {
            document.body.classList.remove("homepage-transition");
        }
    });
};